html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

body,
table,
figure {
  padding: 0;
  margin: 0;

  font-family: sans-serif, Arial, Verdana, 'Trebuchet MS', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
}

.ck-focused {
  border: none !important;
  box-shadow: none !important;
}

.editor__container {
  width: 100%;
  overflow-x: hidden;
}

.editor__container::-webkit-scrollbar {
  width: 8px;
}

.editor__container::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

.ck.ck-toolbar {
  border-top: none !important;
  border-bottom: none !important;
  background-color: white !important;
  border-radius: 0 !important;
  border-left: 1px solid #eceff1;
  border-right: none !important;
}

.arrow-down {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #0f4c81;
  position: absolute;
  bottom: 5px;
  right: 2px;
}

.arrow-down--disabled {
  border-top: 5px solid #b5b5b5;
}

.menu-popover {
  margin-top: 52px;
  margin-left: 16px;
}

.dataroom-menu-popover {
  margin-top: 42px;
  margin-left: -7%;
}

.unit-top-margin {
  margin-top: 1rem;
}

.unit-right-margin {
  margin-right: 1rem;
}

.unit-top-margin-2 {
  margin-top: 2rem !important;
}

.unit-bottom-margin {
  margin-bottom: 1rem;
}

.unit-left-margin {
  margin-left: 1rem;
}

.ellipsis-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
}

.italic-text {
  font-style: italic;
}

.flex-vertical-center {
  align-self: center;
}

.relative-position {
  position: relative;
}

.cast-out-page {
  display: none;
}

.react-colorful {
  height: 110px !important;
  position: absolute !important;
  right: 0;
  top: 54px;
  z-index: 1000;
}

.row {
  font-family: "'Mulish', sans-serif";
}

.rowLandingPad,
.rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  width: 500px;
}

.rowLandingPad *,
.rowCancelPad * {
  opacity: 0 !important;
}

.rowToolbar,
.toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rowLabel {
  padding: 10px 8px 6px;
}

.rowToolbar {
  flex: 0 1 auto;
  display: flex;
}

.rowToolbar,
.toolbarButton {
  height: 100%;
}

.toolbarButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.collapseButton,
.expandButton {
  appearance: none;
  background: none;
  border: none;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 18px;
  padding-left: 120px;
  z-index: 1000;
  transform: translate(-50%, -50%);
}

.collapseButton:focus,
.expandButton:focus {
  outline: 0;
}

.expandButton,
.collapseButton {
  padding-left: 0;
}

.expandButton::before,
.collapseButton::before {
  content: '';
  border: solid #7b7b7b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 16px;
}

.collapseButton:before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.expandButton:before {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.node {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  height: 40px;
  margin-bottom: 0;
}

.nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  margin: 3px;
}

.highlight {
  height: 100%;
}

.node-selected {
  background-color: rgba(15, 76, 129, 0.12);
  border-radius: 4px;
}

.page-break {
  position: relative;
  clear: both;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-break-label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: 700;
  color: #333;
  background: #fff;
  box-shadow: 2px 2px 1px rgb(0 0 0 / 15%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.page-break-border {
  content: '';
  position: absolute;
  border-bottom: 2px dashed #c4c4c4;
  width: 100%;
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
  padding: 0 !important;
}

.search-highlight,
.sr-term {
  background-color: #bbdefb;
  color: white;
}

.search-highlight--active {
  background-color: #002554;
  color: white;
}

.preview-highlight {
  background-color: #f5dbc5;
}
